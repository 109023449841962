import React, { FC } from "react";
import styled, { css } from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { stylesWrapper, wysiwygStyles } from "@theme/commonStyles";
import { graphql } from "gatsby";
import { BackButton } from "@components/atoms/BackButton";
import { Routes } from "@routes";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Localize } from "@components/atoms/Localize";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Dots } from "@components/atoms/Dots";
import { Icon } from "@iconify/react";
import { Contact } from "@components/molecules/Contact";
import ReactHtmlParser from "react-html-parser";
import HtmlParser from "react-html-parser";
import { breakpoints } from "@theme/breakpoints";
import { useLocation } from "@reach/router";
import { ReviewsSection } from "@components/molecules/ReviewsSection";
import { stripHtml } from "@utils/stripHtml";
import { Gallery } from "@components/atoms/Gallery";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { Gallery as PhotoSwipeGallery, Item } from "react-photoswipe-gallery";

const StyledOurProjectHeroWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 40px;

  ${breakpoints.tabletMax`
    padding-bottom: 0;
  `}
`;

const StyledOurProjectHeroInnerWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 220px;

  ${breakpoints.tabletMax`
    max-width: 800px;
    margin-top: 180px;
  `}

  ${breakpoints.phoneMax`
    max-width: 800px;
    margin-top: 140px;
  `}
`;

const StyledBackButton = styled(BackButton)`
  margin-bottom: 20px;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  ${breakpoints.tabletMax`
   flex-direction: column;
    margin: 40px auto 0;
  `}
`;

const StyledLeftWrapper = styled.div`
  ${breakpoints.tabletMax`
    width: 100%;
  `}
`;

const StyledTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 6.4rem;
  font-weight: 700;
  max-width: 650px;

  ${breakpoints.phoneMax`
    font-size: 3.4rem;
    margin: 0 0 20px;
  `}
`;

const StyledShortDescription = styled.p`
  max-width: 530px;
  margin: 0;
  ${wysiwygStyles}

  ${breakpoints.tabletMax`
    max-width: 800px;
  `}
`;

const StyledImageWrapper = styled.div`
  width: 740px;
  height: 546px;
  position: relative;
  margin-left: 50px;

  ${breakpoints.tabletMax`
    width: 100%;
    height: 60vw;
    margin-left: 0;
    margin-top: 60px;
    max-height: 500px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 30px;
  width: 100%;
  height: 100%;
`;

const StyledDots = styled(Dots)`
  position: absolute;
  z-index: -1;
  width: 120%;
  height: 120%;
  border-radius: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${breakpoints.tabletMax`
    display: none;
  `}
`;

const StyledPropertiesWrapper = styled.div`
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
  width: fit-content;
`;

const StyledProperty = styled.div`
  display: flex;
  align-items: center;

  span {
    flex: 1;
  }
`;

const StyledPropertyIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 3rem;
`;

const sectionStyles = css`
  ${stylesWrapper};
  margin-top: 140px;

  ${breakpoints.tabletMax`
    max-width: 800px;
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const StyledSectionImageTextWrapper = styled.section`
  ${sectionStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.tabletMax`
    flex-direction: column-reverse;
  `}
`;

const sectionImageStyles = css`
  width: 700px;
  height: 510px;

  border-radius: 50px;
  .swiper {
    overflow: hidden;
    isolation: isolate;
    border-radius: 50px;
  }

  ${breakpoints.laptopMax`
    width: 650px;
    height: 480px;
  `}

  ${breakpoints.tabletMax`
    width: 100%;
    height: 60vw;
    max-height: 540px;
    margin-top: 30px;
    border-radius: 40px;

    .swiper {
      border-radius: 40px;
    }
  `}

  ${breakpoints.phoneMax`
    border-radius: 30px;

    .swiper {
      border-radius: 30px;
    }
  `}
`;

const StyledSectionImage = styled(GatsbyImage)`
  ${sectionImageStyles}
`;

const StyledSectionIframe = styled.iframe`
  ${sectionImageStyles}
`;

const headlineStyles = css`
  h2 {
    font-size: 4.8rem;
    font-weight: 700;
    margin: 0 0 30px;

    span {
      text-decoration: none !important;
      color: ${({ theme }) => theme.primary};
    }
  }

  ${breakpoints.phoneMax`
    h2 {
      font-size: 2.4rem;
      line-height: 1.5;
      margin: 0 0 20px;
    }
  `}
`;

const StyledRightWrapper = styled.div`
  flex: 1;
  max-width: 550px;
  margin-left: 50px;
  ${headlineStyles};

  p {
    font-size: 1.8rem;
    ${wysiwygStyles};
  }

  ${breakpoints.tabletMax`
    max-width: unset;
    margin-left: unset;
  `}
`;

const StyledSectionGallery = styled.section`
  ${sectionStyles};
`;

const StyledSectionGalleryInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -60px;
  justify-content: center;

  @media (max-width: 1270px) {
    margin-right: 0;
  }
`;

const StyledGalleryImageWrapper = styled.div`
  width: 45%;
  margin-right: 60px;
  margin-bottom: 60px;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  @media (max-width: 1270px) {
    width: 100%;
    height: auto;
    margin-right: 0;

    :last-of-type {
      margin-bottom: 0;
    }
  }

  ${breakpoints.phoneMax`
    margin-bottom: 20px;
  `}
`;

const StyledGalleryImage = styled(GatsbyImage)`
  width: 100%;
`;

const StyledGalleryWrapper = styled.div<{ $secondary?: boolean }>`
  ${sectionImageStyles};
`;

const StyledSectionText = styled.section`
  ${sectionStyles};
  ${headlineStyles};

  p {
    font-size: 1.8rem;
    ${wysiwygStyles};
  }
`;

const StyledSectionHeadline = styled(SectionHeadline)`
  text-align: center;
  margin-bottom: 80px;
  font-weight: 600;

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const StyledSliderWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;

  .swiper {
    border-radius: 50px;
    overflow: hidden;
    isolation: isolate;
  }

  border: 2px solid ${({ theme }) => theme.washLight};

  ${breakpoints.tabletMax`
    width: 90vw;
    max-width: 800px;
  `}

  ${breakpoints.phoneMax`
    border-radius: 30px;

    .swiper {
      border-radius: 30px;
    }
  `}
`;

const OurProjectTemplate: FC<{ pageContext: { id: string }; data: Data }> = ({
  data: { alternativeRealisedProject, strapiRealisedProject },
}) => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const {
    name,
    shortDescription,
    featuredImage,
    Property,
    sections,
    reviewNumber,
  } = strapiRealisedProject || alternativeRealisedProject;

  return (
    <BasicTemplate
      title={`${name} | ${t("common:title")}`}
      description={stripHtml(shortDescription.data.shortDescription)}
      ogImage={featuredImage.localFile.childImageSharp.ogImage.src}
      renderHero={() => (
        <StyledOurProjectHeroWrapper>
          <StyledOurProjectHeroInnerWrapper>
            <StyledBackButton
              to={
                (state as any)?.from?.includes(Routes.RealisedProjects)
                  ? Routes.RealisedProjects
                  : Routes.Home
              }
            >
              {(state as any)?.from?.includes(Routes.RealisedProjects)
                ? t("common:back-button-realization")
                : t("common:back-button")}
            </StyledBackButton>
            <Localize
              pageNames={[t("navigation:list.realised-projects"), name]}
            />

            <StyledInnerWrapper>
              <StyledLeftWrapper>
                <StyledTitle>{name}</StyledTitle>
                <StyledShortDescription
                  dangerouslySetInnerHTML={{
                    __html: shortDescription.data.shortDescription,
                  }}
                />

                {Property && (
                  <StyledPropertiesWrapper>
                    {Property.map(({ iconName, text }, index) => (
                      <StyledProperty key={index}>
                        <StyledPropertyIcon icon={`carbon:${iconName}`} />
                        <span>{text}</span>
                      </StyledProperty>
                    ))}
                  </StyledPropertiesWrapper>
                )}
              </StyledLeftWrapper>

              <StyledImageWrapper>
                <StyledImage
                  image={getImage(
                    featuredImage.localFile.childImageSharp.gatsbyImageData
                  )}
                  alt=""
                />
                <StyledDots />
              </StyledImageWrapper>
            </StyledInnerWrapper>
          </StyledOurProjectHeroInnerWrapper>
        </StyledOurProjectHeroWrapper>
      )}
    >
      {sections.map(
        (
          { content, image, images, strapi_component, videoLink, headline },
          index
        ) => (
          <React.Fragment key={index}>
            {(() => {
              switch (strapi_component) {
                case "sections.video-text":
                case "sections.image-text":
                  return (
                    <StyledSectionImageTextWrapper>
                      {videoLink ? (
                        <StyledSectionIframe
                          src={videoLink}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      ) : (
                        <StyledSectionImage
                          image={getImage(
                            image.localFile.childImageSharp.gatsbyImageData
                          )}
                          alt=""
                        />
                      )}

                      <StyledRightWrapper>
                        {ReactHtmlParser(content.data.content)}
                      </StyledRightWrapper>
                    </StyledSectionImageTextWrapper>
                  );
                case "sections.gallery":
                  return (
                    <StyledSectionGallery>
                      {headline && (
                        <StyledSectionHeadline>
                          {headline}
                        </StyledSectionHeadline>
                      )}
                      <StyledSectionGalleryInnerWrapper>
                        <PhotoSwipeGallery>
                          {images.map(
                            (
                              {
                                localFile: {
                                  childImageSharp: { gatsbyImageData: item },
                                },
                              }: any,
                              i: number
                            ) => (
                              <Item
                                original={(item.images as any).fallback.src}
                                width={item.width as number}
                                height={item.height as number}
                                key={i}
                              >
                                {({ ref, open }) => (
                                  <StyledGalleryImageWrapper
                                    ref={ref as any}
                                    onClick={open}
                                  >
                                    <StyledGalleryImage
                                      image={getImage(item)}
                                      alt=""
                                      objectFit="contain"
                                    />
                                  </StyledGalleryImageWrapper>
                                )}
                              </Item>
                            )
                          )}
                        </PhotoSwipeGallery>
                      </StyledSectionGalleryInnerWrapper>
                    </StyledSectionGallery>
                  );
                case "sections.text":
                  return (
                    <StyledSectionText>
                      {HtmlParser(content.data.content)}
                    </StyledSectionText>
                  );
                case "sections.gallery-text":
                  return (
                    <StyledSectionImageTextWrapper>
                      <StyledGalleryWrapper>
                        <Gallery
                          id={index}
                          items={images.map(
                            ({
                              localFile: {
                                childImageSharp: { gatsbyImageData },
                              },
                            }: any) => gatsbyImageData
                          )}
                          width="740px"
                          height="540px"
                        />
                      </StyledGalleryWrapper>

                      <StyledRightWrapper>
                        {ReactHtmlParser(
                          content.data.content.replace(/\s\s+/g, "")
                        )}
                      </StyledRightWrapper>
                    </StyledSectionImageTextWrapper>
                  );
                case "sections.gallery-slider":
                  return (
                    <StyledSectionGallery>
                      {headline && (
                        <StyledSectionHeadline>
                          {headline}
                        </StyledSectionHeadline>
                      )}
                      <StyledSliderWrapper>
                        <Gallery
                          id={index}
                          items={images.map(
                            ({
                              localFile: {
                                childImageSharp: { gatsbyImageData },
                              },
                            }: any) => gatsbyImageData
                          )}
                        />
                      </StyledSliderWrapper>
                    </StyledSectionGallery>
                  );
              }
            })()}
          </React.Fragment>
        )
      )}
      {typeof reviewNumber === "number" && (
        <ReviewsSection tertiary initialSlide={reviewNumber - 1} />
      )}
      <Contact />
    </BasicTemplate>
  );
};

interface Data {
  strapiRealisedProject: RealisedProject;
  alternativeRealisedProject: RealisedProject;
}

interface RealisedProject {
  name: string;
  shortDescription: {
    data: {
      shortDescription: string;
    };
  };
  featuredImage: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: ImageDataLike;
        ogImage: { src: string };
      };
    };
  };
  reviewNumber: number;
  sections: any[];
  Property: { iconName: string; text: string }[];
}

export const query = graphql`
  query ($language: String!, $strapi_id: Int!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiRealisedProject(
      locale: { eq: $language }
      strapi_id: { eq: $strapi_id }
    ) {
      name
      shortDescription {
        data {
          shortDescription
        }
      }
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
            ogImage: fixed(quality: 70, toFormat: JPG) {
              src
            }
          }
        }
      }
      reviewNumber
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_VIDEO_TEXT {
          strapi_component
          videoLink
          content {
            data {
              content
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTIONS_GALLERY {
          id
          strapi_component
          headline
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED)
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_GALLERY_SLIDER {
          id
          strapi_component
          headline
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED)
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_GALLERY_TEXT {
          strapi_component
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED)
              }
            }
          }
          content {
            data {
              content
            }
          }
        }
      }
      Property {
        iconName
        text
      }
    }

    alternativeRealisedProject: strapiRealisedProject(
      locale: { eq: $language }
      localizations: { data: { elemMatch: { id: { eq: $strapi_id } } } }
    ) {
      name
      shortDescription {
        data {
          shortDescription
        }
      }
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
            ogImage: fixed(quality: 70, toFormat: JPG) {
              src
            }
          }
        }
      }
      reviewNumber
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_VIDEO_TEXT {
          strapi_id
          videoLink
          strapi_component
          content {
            data {
              content
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_GALLERY_SLIDER {
          id
          strapi_component
          headline
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED)
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_GALLERY {
          strapi_id
          strapi_component
          headline
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED)
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_SECTIONS_GALLERY_TEXT {
          strapi_id
          strapi_component
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: BLURRED)
              }
            }
          }
          content {
            data {
              content
            }
          }
        }
      }
      Property {
        iconName
        text
      }
    }
  }
`;

export default OurProjectTemplate;
